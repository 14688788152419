import React from 'react';
// react-boostrap
import {   Row } from 'react-bootstrap'

const userViewDetails = () => {
    return (<>
        <Row className="mb-2">
            <div className="col-3">
                <h6>First Name:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Bni</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Last Name:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Jhon</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>User Name:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Bni@01</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>City:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">california </p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>State:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">California</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Country:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">USA</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Gender:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Male</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Date of birth:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">08/26/2023</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Father Name:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">John</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Mother Name:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Bni</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Mother Name:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Bni</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>language:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">English, French</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>School:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Hacienda Elementary School</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Degree:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">California City College</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Field of Study:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Music and dance</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Activities:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Dancing, Painting</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Achievements:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Capezio Dance Award</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Goals:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Make a certain dance team, cheerleading team</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Hobbies:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">My hobby is dancing, as it gives me joy and happiness.</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Certifications:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">National Dance Council of America (NDCA)</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Awards:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Capezio Dance Award</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Area of Interest:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Dance</p>
            </div>
        </Row>
        <hr />
        <Row className="mb-2">
            <div className="col-3">
                <h6>About Me:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Hi, I’m James, I’m 36 and I work as a Digital Designer for the “Daydreams” Agency in Pier 56</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Email:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Bnijohn@gmail.com</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Mobile:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">(001) 4544 565 456</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Address:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">United States of America</p>
            </div>
        </Row>
        <Row className="row mb-2">
            <div className="col-3">
                <h6>Social Link:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">www.bootstrap.com</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Birth Date:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">24 January</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Birth Year:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">1994</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Birthplace:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Austin, Texas, USA</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Lives in:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">San Francisco, California, USA</p>
            </div>
        </Row>

        <Row className="mb-2">
            <div className="col-3">
                <h6>Interested in:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Designing</p>
            </div>
        </Row>

        <Row className="mb-2">
            <div className="col-3">
                <h6>Joined:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">April 31st, 2014</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Status:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Married</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Phone Number:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">(044) 555 - 4369 - 8957</p>
            </div>
        </Row>
        <Row className="mb-3">
            <div className="col-3">
                <h6>Political Incline:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">Democrat</p>
            </div>
        </Row>
        <h4 className="mt-2">Websites and Social Links</h4>
        <hr />
        <Row className="mb-2">
            <div className="col-3">
                <h6>Website:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">www.bootstrap.com</p>
            </div>
        </Row>
        <Row className="mb-2">
            <div className="col-3">
                <h6>Social Link:</h6>
            </div>
            <div className="col-9">
                <p className="mb-0">www.bootstrap.com</p>
            </div>
        </Row>
    </>)
}

export default userViewDetails;