import React, { useState } from 'react'
import { Row, Col, Container, Dropdown, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'

//image
import user1 from '../../../assets/images/user/1.jpg'
import user01 from '../../../assets/images/user/01.jpg'
import user2 from '../../../assets/images/user/02.jpg'
import user3 from '../../../assets/images/user/03.jpg'
import user4 from '../../../assets/images/user/04.jpg'
import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import img1 from '../../../assets/images/small/07.png'
import img2 from '../../../assets/images/small/08.png'
import img3 from '../../../assets/images/small/09.png'
import img4 from '../../../assets/images/small/10.png'
import img5 from '../../../assets/images/small/11.png'
import img6 from '../../../assets/images/small/12.png'
import img7 from '../../../assets/images/small/13.png'
import img8 from '../../../assets/images/small/14.png'
import p1 from '../../../assets/images/page-img/p1.jpg'
import s1 from '../../../assets/images/page-img/s1.jpg'
import s2 from '../../../assets/images/page-img/s2.jpg'
import s3 from '../../../assets/images/page-img/s3.jpg'
import s4 from '../../../assets/images/page-img/s4.jpg'
import s5 from '../../../assets/images/page-img/s5.jpg'
import p2 from '../../../assets/images/page-img/p2.jpg'
import p3 from '../../../assets/images/page-img/p3.jpg'
import p4 from '../../../assets/images/page-img/p4.jpg'
import p5 from '../../../assets/images/page-img/p5.jpg'
import p6 from '../../../assets/images/page-img/p6.JPG'
import p7 from '../../../assets/images/page-img/p7.JPG'
import p9 from '../../../assets/images/page-img/p9.JPG'
import p10 from '../../../assets/images/page-img/p10.JPG'
import img42 from '../../../assets/images/page-img/42.png'
import icon1 from '../../../assets/images/icon/01.png'
import icon2 from '../../../assets/images/icon/02.png'
import icon3 from '../../../assets/images/icon/03.png'
import icon4 from '../../../assets/images/icon/04.png'
import icon5 from '../../../assets/images/icon/05.png'
import icon6 from '../../../assets/images/icon/06.png'
import icon7 from '../../../assets/images/icon/07.png'
import img9 from '../../../assets/images/small/img-1.jpg'
import img10 from '../../../assets/images/small/img-2.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import RichTextForm from './common-components/RichTextForm'


const FriendCity =() =>{
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);

    const [show5, setShow5] = useState(false); 
    const handleCloseSubmit = () => { 
            setShow(false);
            setShow5(true);
        }
        const handleShow = () => setShow(true);
       
        const handleClose5 = () => setShow5(false);
    
    return (
        <>
            {/* <ProfileHeader title="Classmate" img={img3}/> */}
            <div id="content-page" className="content-page">
                <Container>
                    <Row>
                        <Col lg={8} className="row m-0 p-0">
                            <Col sm={12} >
                                <Card id="post-modal-data" className="card-block card-stretch card-height">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Create a Post</h4>
                                        </div>
                                    </div>
                                    <Card.Body >
                                        <div className="d-flex align-items-center">
                                            <div className="user-img">
                                                <img src={user1} alt="user1" className="avatar-60 rounded-circle" />
                                            </div>
                                            <form className="post-text ms-3 w-100 " onClick={handleShow}>
                                                <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }} />
                                            </form>
                                        </div>
                                        <hr></hr>
                                        <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                            <li className="me-3 mb-md-0 mb-2">
                                                <Link to="#" className="btn btn-soft-primary">
                                                    <img src={img1} alt="icon" className="img-fluid me-2" /> Photo/Video
                                                </Link>
                                            </li>
                                            <li className="me-3 mb-md-0 mb-2">
                                                <Link to="#" className="btn btn-soft-primary">
                                                    <img src={img2} alt="icon" className="img-fluid me-2" /> Tag Friend
                                                </Link>
                                            </li>
                                            <li className="me-3">
                                                <Link to="#" className="btn btn-soft-primary">
                                                    <img src={img3} alt="icon" className="img-fluid me-2" /> Feeling/Activity
                                                </Link>
                                            </li>
                                            <li>
                                                <button className=" btn btn-soft-primary">
                                                    <div className="card-header-toolbar d-flex align-items-center">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as='div' className="lh-1">
                                                                <span className="material-symbols-outlined">
                                                                    more_horiz
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={handleShow} href="#">Check in</Dropdown.Item>
                                                                <Dropdown.Item onClick={handleShow} href="#">Live Video</Dropdown.Item>
                                                                <Dropdown.Item onClick={handleShow} href="#">Gif</Dropdown.Item>
                                                                <Dropdown.Item onClick={handleShow} href="#">Watch Party</Dropdown.Item>
                                                                <Dropdown.Item onClick={handleShow} href="#">Play with Friend</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </button>
                                            </li>
                                        </ul>
                                    </Card.Body>
                                    <Modal size="lg" className="fade" id="post-modal" onHide={handleClose} show={show} style={{ zIndex: '9999' }} >
                                        <Modal.Header className="d-flex justify-content-between">
                                            <Modal.Title id="post-modalLabel">Create Post for City Friends</Modal.Title>
                                            <Link to="#" className="lh-1" onClick={handleClose} >
                                                <span className="material-symbols-outlined">close</span>
                                            </Link>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="d-flex align-items-center">
                                                <div className="user-img">
                                                    <img src={user1} alt="user1" className="avatar-60 rounded-circle img-fluid" />
                                                </div>
                                                <form className="post-text ms-3 w-100 " data-bs-toggle="modal" data-bs-target="#post-modal" style={{height:'18rem'}}>
                                                    {/* <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }} /> */}
                                                    <RichTextForm />
                                                </form>
                                            </div>
                                            <hr />                                            
                                            {/* <div className="other-option">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div className="user-img me-3">
                                                            <img src={user1} alt="user1" className="avatar-60 rounded-circle img-fluid" />
                                                        </div>
                                                        <h6>Your Story</h6>
                                                    </div>
                                                    <div className="card-post-toolbar">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} role="button">
                                                                <span className="btn btn-primary">Friend</span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className=" m-0 p-0">
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-save-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Public</h6>
                                                                            <p className="mb-0">Anyone on or off Facebook</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-close-circle-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Friends</h6>
                                                                            <p className="mb-0">Your Friend on facebook</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-user-unfollow-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Friends except</h6>
                                                                            <p className="mb-0">Don't show to some friends</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-notification-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Only Me</h6>
                                                                            <p className="mb-0">Only me</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div> */}
                                           <button type="submit" className="btn btn-primary d-block w-100 mt-3"  onClick={handleCloseSubmit}>Submit</button>
                                    </Modal.Body>
                                </Modal> 
                                <Modal centered size="sm" show={show5} onHide={handleClose5} style={{zIndex:'9999'}}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Alert!</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Your post has been saved and will be published following verification.</Modal.Body>
                                    <Modal.Footer>
                                        {/* <Button variant="secondary" onClick={handleClose5}>
                                            Close
                                        </Button>                                        */}
                                    </Modal.Footer>
                                </Modal> 
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className=" card-block card-stretch card-height">
                                    <Card.Body>
                                        <div className="user-post-data">
                                            <div className="d-flex justify-content-between">
                                                <div className="me-3">
                                                    <img className="rounded-circle img-fluid" src={user2} alt="" />
                                                </div>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h5 className="mb-0 d-inline-block">Aaradhya</h5>
                                                            <span className="mb-0 ps-1 d-inline-block">Add New Post</span>
                                                            <p className="mb-0 text-primary">Just Now</p>
                                                        </div>
                                                        <div className="card-post-toolbar">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="bg-transparent">
                                                                    <span className="material-symbols-outlined">
                                                                        more_horiz
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <div className="h4 material-symbols-outlined">
                                                                                <i className="ri-save-line"></i>
                                                                            </div>
                                                                            <div className="data ms-2">
                                                                                <h6>Save Post</h6>
                                                                                <p className="mb-0">Add this to your saved items</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-close-circle-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Hide Post</h6>
                                                                                <p className="mb-0">See fewer posts like this.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-user-unfollow-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Unfollow User</h6>
                                                                                <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-notification-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Notifications</h6>
                                                                                <p className="mb-0">Turn on notifications for this post</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <p>Do younger kids stand to benefit from science fiction enough that it should be actively taught in
                                                elementary schools? In high school it's fairly common, but I've discovered in my research (see an
                                                article about my results below) that elementary teachers and librarians don't tend to choose
                                                science fiction for activities and read alouds, but only recommend it to individual readers. </p>
                                        </div>
                                        <div className="user-post">
                                            {/* <div className=" d-grid grid-rows-2 grid-flow-col gap-3"> */}

                                            <div> <div className="row-span-2 row-span-md-1">
                                                <img src={p9} alt="post1" className="img-fluid rounded w-100" />
                                            </div>
                                                {/* <div className="row-span-1">
                                                    <img src={p1} alt="post2" className="img-fluid rounded w-100"/>
                                                </div>
                                                <div className="row-span-1 ">
                                                    <img src={p3} alt="post3" className="img-fluid rounded w-100"/>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="comment-area mt-3">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="like-block position-relative d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="like-data">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                    <img src={icon1} className="img-fluid" alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className=" py-2">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="total-like-block ms-2 me-3">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                    140 Likes
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Other</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="total-comment-block">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                20 Comment
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <ShareOffcanvas />
                                            </div>
                                            <hr />
                                            <ul className="post-comments list-inline p-0 m-0">
                                                <li className="mb-2">
                                                    <div className="d-flex">
                                                        <div className="user-img">
                                                            <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Sloka</h6>
                                                            <p className="mb-0">I try to make my video essays accessible for high school level, but I would struggle for
                                                                younger than that</p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="user-img">
                                                            <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Aslesha</h6>
                                                            <p className="mb-0">When I was in my elementary, middle and high schools moving up was exciting
                                                                because it meant that I would finally have NEW sci-fi books to read for free. It was only
                                                                in high school I could afford to buy books on my own and thus began my lifelong habit
                                                                of buying new sci-fi books, reading them and donating most (not all) to local libraries.
                                                                Some I kept to read again and again. Now I am (technical ly) a writer in my own right
                                                                and I have donated dozens of copies of my own novels to libraries in the hope that new
                                                                readers will enjoy my work. </p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="comment-text d-flex align-items-center mt-3" >
                                                <input type="text" className="form-control rounded" placeholder="Enter Your Comment" />
                                                <div className="comment-attagement d-flex">
                                                    <Link to="#"><i className="ri-link me-3"></i></Link>
                                                    <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                    <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                                </div>
                                            </form>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <div className="card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <div className="user-post-data">
                                            <div className="d-flex justify-content-between">
                                                <div className="me-3">
                                                    <img className="rounded-circle img-fluid" src={user3} alt="" />
                                                </div>
                                                <div className="w-100">
                                                    <div className="d-flex  justify-content-between">
                                                        <div>
                                                            <h5 className="mb-0 d-inline-block">Omniverse Museum</h5>
                                                            <span className="mb-0 ps-1 d-inline-block">Added New Image in a Post</span>
                                                            <p className="mb-0 text-primary">1 hour ago</p>
                                                        </div>
                                                        <div className="card-post-toolbar">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="bg-transparent">
                                                                    <span className="material-symbols-outlined">
                                                                        more_horiz
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <div className="h4">
                                                                                <i className="ri-save-line"></i>
                                                                            </div>
                                                                            <div className="data ms-2">
                                                                                <h6>Save Post</h6>
                                                                                <p className="mb-0">Add this to your saved items</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-close-circle-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Hide Post</h6>
                                                                                <p className="mb-0">See fewer posts like this.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-user-unfollow-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Unfollow User</h6>
                                                                                <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-notification-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Notifications</h6>
                                                                                <p className="mb-0">Turn on notifications for this post</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <h3>KIDS ARE ALLOWED</h3>
                                            <p>Cal ling al l young explorers! Bring your kids to the Omniverse Museum, where they're not just
                                                allowed, but encouraged to venture through over 20 out-of-this-world portals. Let their
                                                imagination take fl ight!</p>
                                        </div>
                                        <div className="user-post">
                                            <Link to="#"><img src={p10} alt="post1" className="img-fluid rounded w-100" /></Link>
                                        </div>
                                        <div className="comment-area mt-3">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="like-block position-relative d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="like-data">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                    <img src={icon1} className="img-fluid" alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className=" py-2">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="total-like-block ms-2 me-3">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                    140 Likes
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Other</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="total-comment-block">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                20 Comment
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <ShareOffcanvas />
                                            </div>
                                            <hr />
                                            <ul className="post-comments list-inline p-0 m-0">
                                                <li className="mb-2">
                                                    <div className="d-flex ">
                                                        <div className="user-img">
                                                            <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Monty Carlo</h6>
                                                            <p className="mb-0">hm entrance po </p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex ">
                                                        <div className="user-img">
                                                            <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Paul Molive</h6>
                                                            <p className="mb-0">CJ you should go there while you can </p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="comment-text d-flex align-items-center mt-3" >
                                                <input type="text" className="form-control rounded" placeholder="Enter Your Comment" />
                                                <div className="comment-attagement d-flex">
                                                    <Link to="#"><i className="ri-link me-3"></i></Link>
                                                    <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                    <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <div className="user-post-data">
                                            <div className="d-flex justify-content-between">
                                                <div className="me-3">
                                                    <img className="rounded-circle img-fluid" src={user4} alt="" />
                                                </div>
                                                <div className="w-100">
                                                    <div className=" d-flex  justify-content-between">
                                                        <div>
                                                            <h5 className="mb-0 d-inline-block">Jyotsna</h5>
                                                            <p className="mb-0 ps-1 d-inline-block">Update her Status</p>
                                                            <p className="mb-0 text-primary">6 hour ago</p>
                                                        </div>
                                                        <div className="card-post-toolbar">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="bg-transparent">
                                                                    <span className="material-symbols-outlined">
                                                                        more_horiz
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <div className="h4">
                                                                                <i className="ri-save-line"></i>
                                                                            </div>
                                                                            <div className="data ms-2">
                                                                                <h6>Save Post</h6>
                                                                                <p className="mb-0">Add this to your saved items</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-close-circle-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Hide Post</h6>
                                                                                <p className="mb-0">See fewer posts like this.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-user-unfollow-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Unfollow User</h6>
                                                                                <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-notification-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Notifications</h6>
                                                                                <p className="mb-0">Turn on notifications for this post</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <p>COOL IDEA TO HELP LABOURS</p>
                                            <p>WHO CARRY WEIGHT DURING
                                                CONSTRUCTION WORK </p>
                                        </div>
                                        <div className="user-post">
                                            <Link to="#"><img src={p6} alt="post1" className="img-fluid rounded w-100" /></Link>
                                        </div>
                                        <div className="comment-area mt-3">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="like-block position-relative d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="like-data">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                    <img src={icon1} className="img-fluid" alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className=" py-2">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="total-like-block ms-2 me-3">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                    140 Likes
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Other</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="total-comment-block">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                20 Comment
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <ShareOffcanvas />
                                            </div>
                                            <hr />
                                            <ul className="post-comments list-inline p-0 m-0">
                                                <li className="mb-2">
                                                    <div className="d-flex">
                                                        <div className="user-img">
                                                            <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Vashistha</h6>
                                                            <p className="mb-0">What a great idea! </p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex ">
                                                        <div className="user-img">
                                                            <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Aadhya</h6>
                                                            <p className="mb-0">Very informative Share more
                                                                .
                                                                ..</p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="comment-text d-flex align-items-center mt-3" >
                                                <input type="text" className="form-control rounded" placeholder="Enter Your Comment" />
                                                <div className="comment-attagement d-flex">
                                                    <Link to="#"><i className="ri-link me-3"></i></Link>
                                                    <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                    <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <div className="post-item">
                                            <div className="d-flex justify-content-between">
                                                <div className="me-3">
                                                    <img className="rounded-circle img-fluid avatar-60" src={user1} alt="" />
                                                </div>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h5 className="mb-0 d-inline-block">Hindhavi</h5>
                                                            <p className="ms-1 mb-0 ps-1 d-inline-block">Changed Profile Picture</p>
                                                            <p className="mb-0">3 day ago</p>
                                                        </div>
                                                        <div className="card-post-toolbar">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="bg-transparent">
                                                                    <span className="material-symbols-outlined">
                                                                        more_horiz
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <div className="h4">
                                                                                <i className="ri-save-line"></i>
                                                                            </div>
                                                                            <div className="data ms-2">
                                                                                <h6>Save Post</h6>
                                                                                <p className="mb-0">Add this to your saved items</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-close-circle-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Hide Post</h6>
                                                                                <p className="mb-0">See fewer posts like this.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-user-unfollow-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Unfollow User</h6>
                                                                                <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-notification-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Notifications</h6>
                                                                                <p className="mb-0">Turn on notifications for this post</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div><p>Find Tan A</p></div>
                                        <div className="user-post text-center">
                                            <Link to="#"><img src={p7} alt="post1" className="img-fluid rounded w-100 mt-3" /></Link>
                                        </div>
                                        <div className="comment-area mt-3">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="like-block position-relative d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="like-data">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                    <img src={icon1} className="img-fluid" alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className=" py-2">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="total-like-block ms-2 me-3">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                    140 Likes
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Other</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="total-comment-block">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                20 Comment
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <ShareOffcanvas />
                                            </div>
                                            <hr />
                                            <ul className="post-comments list-inline p-0 m-0">
                                                <li className="mb-2">
                                                    <div className="d-flex">
                                                        <div className="user-img">
                                                            <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Parakrama Abeygunawardane</h6>
                                                            <p className="mb-0">Tan A=12/5 </p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="user-img">
                                                            <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Viswanadhan</h6>
                                                            <p className="mb-0">CA=5 <br />
                                                                tan A= 12/5</p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="comment-text d-flex align-items-center mt-3" >
                                                <input type="text" className="form-control rounded" placeholder="Enter Your Comment" />
                                                <div className="comment-attagement d-flex">
                                                    <Link to="#"><i className="ri-link me-3"></i></Link>
                                                    <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                    <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="card card-block card-stretch card-height">
                                    <div className="card-body">
                                        <div className="user-post-data">
                                            <div className="d-flex justify-content-between">
                                                <div className="me-3">
                                                    <img className="rounded-circle img-fluid" src={user2} alt="" />
                                                </div>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h5 className="mb-0 d-inline-block">Kids Art & Craft</h5>
                                                            <p className="mb-0 ps-1 d-inline-block">Added New Video in his Timeline</p>
                                                            <p className="mb-0 text-primary">1 day ago</p>
                                                        </div>
                                                        <div className="card-post-toolbar">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="bg-transparent">
                                                                    <span className="material-symbols-outlined">
                                                                        more_horiz
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <div className="h4">
                                                                                <i className="ri-save-line"></i>
                                                                            </div>
                                                                            <div className="data ms-2">
                                                                                <h6>Save Post</h6>
                                                                                <p className="mb-0">Add this to your saved items</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-close-circle-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Hide Post</h6>
                                                                                <p className="mb-0">See fewer posts like this.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-user-unfollow-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Unfollow User</h6>
                                                                                <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-notification-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Notifications</h6>
                                                                                <p className="mb-0">Turn on notifications for this post</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <p>Cute
                                                D
                                                IY Paper Roll
                                                C
                                                rafts :.: </p>
                                        </div>
                                        <div className="user-post">
                                            <div className="ratio ratio-16x9">
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/vbjnJwe0f-E?si=9EGI5gqvNC1eo5D0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="comment-area mt-3">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="like-block position-relative d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="like-data">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                    <img src={icon1} className="img-fluid" alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className=" py-2">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="total-like-block ms-2 me-3">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                    140 Likes
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                    <Dropdown.Item href="#">Other</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="total-comment-block">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                20 Comment
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <ShareOffcanvas />
                                            </div>
                                            <hr />
                                            <ul className="post-comments list-inline p-0 m-0">
                                                <li className="mb-2">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="user-img">
                                                            <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Bhargav</h6>
                                                            <p className="mb-0">These are delightful !</p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="user-img">
                                                            <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Charan</h6>
                                                            <p className="mb-0">Nenia Vicente Buiza</p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="comment-text d-flex align-items-center mt-3">
                                                <input type="text" className="form-control rounded" placeholder="Enter Your Comment" />
                                                <div className="comment-attagement d-flex">
                                                    <Link to="#"><i className="ri-link me-3"></i></Link>
                                                    <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                    <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                        <Col lg={4}>

                            <Card>
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">City </h4>
                                    </div>
                                </div>
                                <Card.Body>
                                    <ul className="media-story list-inline m-0 p-0">
                                        <li className="d-flex mb-4 align-items-center">
                                            <img src={user01} alt="story3" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Aaradhya </h5>
                                                <p className="mb-0">Hyderabad</p>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4  align-items-center">
                                            <img src={user2} alt="story-img" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Aarsh K</h5>
                                                <p className="mb-0">Bhubaneswar</p>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4  align-items-center">
                                            <img src={user3} alt="story-img" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Drishti  P</h5>
                                                <p className="mb-0">Vizag</p>
                                            </div>
                                        </li>
                                        <li className="d-flex  mb-4 align-items-center">
                                            <img src={user4} alt="story-img" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Eshika  K</h5>
                                                <p className="mb-0">Chandigarh</p>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4  align-items-center">
                                            <img src={user5} alt="story-img" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Dharmesh K</h5>
                                                <p className="mb-0">Chennai</p>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4  align-items-center">
                                            <img src={user6} alt="story-img" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Hiya K</h5>
                                                <p className="mb-0">Bangalore</p>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4  align-items-center">
                                            <img src={user7} alt="story-img" className="rounded-circle img-fluid" />
                                            <div className="stories-data ms-3">
                                                <h5>Bhavik P</h5>
                                                <p className="mb-0">Vizag</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <div className="col-sm-12 text-center">
                        <img src={loader} alt="loader" style={{height: "100px"}}/>
                    </div> */}
                    </Row>
                </Container>
            </div>
        </>
    )

}

export default FriendCity;