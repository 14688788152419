import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS for styling
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function RichTextForm() {
  const [text, setText] = useState('');
  const [editorHtml, setEditorHtml] = useState('');
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [croppedImage, setCroppedImage] = useState(null);
  const imageInputRef = useRef(null);

  const handleImageChange = (e) => {
    e.preventDefault();
    const selectedImage = e.target.files[0];
    setImage(URL.createObjectURL(selectedImage));
  };

  const handleCropComplete = (crop) => {
    // Handle cropping complete event
    console.log(crop);
  };

  const handleTextChange = (value) => {
    setText(value);
  };
  const quillStyles = {
    height: '160px', // Set the desired height here
  };
  RichTextForm.modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  RichTextForm.formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

  return (
    <div style={quillStyles}>
      {/* <h1>Rich Text Form</h1> */}
      {/* <input
        type="file"
        accept="image/*"
        ref={imageInputRef}
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
      <button onClick={() => imageInputRef.current.click()}>Upload Image</button>
      {image && (
        <ReactCrop
          src={image}
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={handleCropComplete}
        />
      )} */}
      <ReactQuill
        value={editorHtml}
        onChange={setEditorHtml}
        modules={RichTextForm.modules}
        formats={RichTextForm.formats}
        style={{ height: '15.8rem' }}
        theme="snow"
      />
    </div>
  );
}
export default RichTextForm;