import React from 'react'
import { useState, useMemo } from 'react';
import { Container, Row, Col, Card, Tab, Form, Button, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Select from 'react-select'
import countryList from 'react-select-country-list'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Datepickers from '../../../components/datepicker';

// import ReactLanguageSelect from 'react-language-selector;'
//image
import img1 from '../../../assets/images/user/11.png';
import UserPersonalProfile from './user_personal_info';

const UserProfileEdit = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = value => {
        setValue(value)
    }
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
      };

      const [country, setCountry] = useState("");
      const [region, setRegion] = useState("");
    //   const [selectedLanguage, setSelectedLanguage] = useState("");

      
    return (
        <>
            <Container>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className="p-0">
                                    <div>
                                        <Nav as="ul" variant="pills" className="iq-edit-profile row">
                                            <Nav.Item as="li" className="col-md-3 p-0">
                                                <Nav.Link eventKey="first" role="button">
                                                    Personal Information
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="col-md-3 p-0">
                                                <Nav.Link eventKey="second" role="button">
                                                    Change Password
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="col-md-3 p-0">
                                                <Nav.Link eventKey="third" role="button">
                                                    Email and SMS
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="col-md-3 p-0">
                                                <Nav.Link eventKey="fourth" role="button">
                                                    Manage Contact
                                                </Nav.Link>
                                            </Nav.Item>

                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            {/* <div className="iq-edit-list-data"> */}
                            <Tab.Content>
                                <Tab.Pane eventKey="first" className="fade show">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Personal Information</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                           <UserPersonalProfile />
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" className="fade show">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">Change Password</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="cpass" className="form-label">Current Password:</Form.Label>
                                                    <Link to="#" className="float-end">Forgot Password</Link>
                                                    <Form.Control type="Password" className="form-control" id="cpass" defaultValue="" />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="npass" className="form-label">New Password:</Form.Label>
                                                    <Form.Control type="Password" className="form-control" id="npass" defaultValue="" />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="vpass" className="form-label">Verify Password:</Form.Label>
                                                    <Form.Control type="Password" className="form-control" id="vpass" defaultValue="" />
                                                </Form.Group>
                                                <Button type="submit" className="btn btn-primary me-2">Submit</Button>
                                                <Button type="reset" className="btn bg-soft-danger">Cancel</Button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third" className="fade show">
                                    <Card>
                                        <Card.Header className="card-header d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Email and SMS</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Group className="form-group row align-items-center">
                                                    <Form.Label className="col-md-3" htmlFor="emailnotification">Email Notification:</Form.Label>
                                                    <Form.Check className="col-md-9 form-check form-switch">
                                                        <Form.Check.Input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked11" defaultChecked />
                                                        <Form.Check.Label className="form-check-label" htmlFor="flexSwitchCheckChecked11">Checked switch checkbox input</Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                                <Form.Group className="form-group row align-items-center">
                                                    <Form.Label className="col-md-3" htmlFor="smsnotification">SMS Notification:</Form.Label>
                                                    <Form.Check className="col-md-9 form-check form-switch">
                                                        <Form.Check.Input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked12" defaultChecked />
                                                        <Form.Check.Label className="form-check-label" htmlFor="flexSwitchCheckChecked12">Checked switch checkbox input</Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                                <Form.Group className="form-group row align-items-center">
                                                    <Form.Label className="col-md-3" htmlFor="npass">When To Email</Form.Label>
                                                    <Col md="9">
                                                        <Form.Check className="form-check">
                                                            <Form.Check.Input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault12" />
                                                            <Form.Check.Label className="form-check-label" htmlFor="flexCheckDefault12">
                                                                You have new notifications.
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="form-check d-block">
                                                            <Form.Check.Input className="form-check-input" type="checkbox" defaultValue="" id="email02" />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email02">You're sent a direct message</Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="form-check d-block">
                                                            <Form.Check.Input type="checkbox" className="form-check-input" id="email03" defaultChecked />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email03">Someone adds you as a connection</Form.Check.Label>
                                                        </Form.Check>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="form-group row align-items-center">
                                                    <Form.Label className="col-md-3" htmlFor="npass">When To Escalate Emails</Form.Label>
                                                    <Col md="9">
                                                        <Form.Check className="form-check">
                                                            <Form.Check.Input className="form-check-input" type="checkbox" defaultValue="" id="email04" />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email04">
                                                                Upon new order.
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="form-check d-block">
                                                            <Form.Check.Input className="form-check-input" type="checkbox" defaultValue="" id="email05" />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email05">New membership approval</Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="form-check d-block">
                                                            <Form.Check.Input type="checkbox" className="form-check-input" id="email06" defaultChecked />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email06">Member registration</Form.Check.Label>
                                                        </Form.Check>
                                                    </Col>
                                                </Form.Group>
                                                <Button type="submit" className="btn btn-primary me-2">Submit</Button>
                                                <Button type="reset" className="btn bg-soft-danger">Cancel</Button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth" className="fade show">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Manage Contact</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="cno" className="form-label">Contact Number:</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="cno" defaultValue="001 2536 123 458" />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="email" className="form-label">Email:</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="email" defaultValue="Bnijone@demo.com" />
                                                </Form.Group>
                                                {/* <Form.Group className="form-group">
                                              <Form.Label htmlFor="url"  className="form-label">Url:</Form.Label>
                                              <Form.Control type="text" className="form-control" id="url" defaultValue="https://getbootstrap.com"/>
                                          </Form.Group> */}
                                                <Form.Group className="form-group col-sm-12">
                                                    <Form.Label className="form-label">Address:</Form.Label>
                                                    <Form.Control as="textarea" className="form-control" rows={5} style={{ lineHeight: "22px" }} placeholder=" 37 Cardinal Lane
                                                  Petersburg, VA 23803
                                                  United States of America
                                                  Zip Code: 85001">
                                                    </Form.Control>
                                                </Form.Group>
                                                <Button type="submit" className="btn btn-primary me-2">Submit</Button>
                                                <Button type="reset" className="btn bg-soft-danger">Cancel</Button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>

                            </Tab.Content>
                            {/* </div> */}
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    )

}

export default UserProfileEdit;