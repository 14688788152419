import {React,useState} from 'react'

import { Row, Card, Container, Col, Form, ListGroupItem, Button, CardGroup ,Modal} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'

// images
import gi1 from '../../../assets/images/page-img/gi-1.jpg'
import gi2 from '../../../assets/images/page-img/gi-2.jpg'
import gi3 from '../../../assets/images/page-img/gi-3.jpg'
import gi4 from '../../../assets/images/page-img/gi-4.jpg'
import gi5 from '../../../assets/images/page-img/gi-5.jpg'
import gi6 from '../../../assets/images/page-img/gi-6.jpg'
import gi7 from '../../../assets/images/page-img/gi-7.jpg'
import gi8 from '../../../assets/images/page-img/gi-8.jpg'
import gi9 from '../../../assets/images/page-img/gi-9.jpg'
import user05 from '../../../assets/images/user/01.jpg'
import user06 from '../../../assets/images/user/02.jpg'
import user07 from '../../../assets/images/user/03.jpg'
import user08 from '../../../assets/images/user/04.jpg'
import user09 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/05.jpg'
import img1 from '../../../assets/images/page-img/profile-bg1.jpg'
import img2 from '../../../assets/images/page-img/profile-bg2.jpg'
import img3 from '../../../assets/images/page-img/profile-bg3.jpg'
import img4 from '../../../assets/images/page-img/profile-bg4.jpg'
import img5 from '../../../assets/images/page-img/profile-bg5.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import img7 from '../../../assets/images/page-img/profile-bg7.jpg'
import img9 from '../../../assets/images/page-img/profile-bg9.jpg'

const Groups = () => {
    const [show6, setShow6] = useState(false);
const handleCloseCreateModal = () => setShow6(false);
const handleCreateModal = () => setShow6(true);

    const items = [
        { id: 1, imageSrc: user05, text: 'Volunteering', members: '240' },
        { id: 2, imageSrc: user06, text: 'Scouting', members: '260' },
        { id: 3, imageSrc: user07, text: 'Sports', members: '150' },
        { id: 4, imageSrc: user08, text: 'Academic', members: '514' },
        { id: 5, imageSrc: user10, text: 'Agricultural', members: '348' },
    ];
    return (
        <>
            {/* <ProfileHeader  img={img7} title="Groups" /> */}
            <div id="content-page" className="content-page">
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card className="position-relative inner-page-bg bg-primary" style={{ height: "150px" }}>
                                <div className="d-flex inner-page-title justify-content-between" style={{width:"90%"}}>
                                    {/* <p className="text-white">lorem ipsum</p> */}
                                    <h3 className="text-white">My Groups</h3>                                   
                                    <Button variant="success" className="btn-block mt-1" onClick={handleCreateModal}>Create Group</Button>{' '}
                                    <Modal centered show={show6} onHide={handleCloseCreateModal} style={{zIndex:"9999"}}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Create group</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <Form>
                                    <Form.Group className="form-group">
                                        <Form.Label>Group Name:</Form.Label>
                                        <Form.Control type="text" id="grouptitle"/>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label>Description:</Form.Label>
                                        <textarea name="description" rows="4" required="" id="address" class="form-control"></textarea>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="exampleFormControlSelect1">Industry:</Form.Label>
                                        <select className="form-select" id="exampleFormControlSelect1">
                                            <option>Select Industry</option>
                                            <option>It Services</option>
                                            <option>Health Care</option>
                                            <option>Education</option>
                                            <option>Fine Arts</option>                                            
                                        </select>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label>Location :</Form.Label>
                                        <Form.Control type="text" id="location"/>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label>Rules:</Form.Label>
                                        <textarea name="description" rows="3" required="" id="address" class="form-control"></textarea>
                                    </Form.Group>                                   
                                    
                                </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseCreateModal}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={handleCloseCreateModal}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <div className="d-grid gap-1 d-grid-template-1fr-19">
                        <ul className="list-group">
                            {items.map((item) => (
                                <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-left">
                                        <img src={item.imageSrc} alt="Item" width="50" height="50" className="mr-3" />

                                        <div className="ms-3">
                                            <p className=" h4 ">{item.text}</p>
                                            <small>{item.members} members</small>
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary btn-sm dropdown-toggle"
                                            type="button"
                                            id={`dropdownMenuButton-${item.id}`}
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="fas fa-ellipsis-h"></i>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${item.id}`}>
                                            <a className="dropdown-item" href="#">Action 1</a>
                                            <a className="dropdown-item" href="#">Action 2</a>
                                            <a className="dropdown-item" href="#">Action 3</a>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Container>
            </div>
        </>
    )

}

export default Groups;