import React from 'react';
import { useState, useMemo } from 'react';
import { Container, Row, Col, Card, Tab, Form, Button, Nav,Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Select from 'react-select'
import countryList from 'react-select-country-list'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
//image
import img1 from '../../../assets/images/user/11.png';

const UserPersonalProfile = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = value => {
        setValue(value)
    }
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    const [showEducation, setShowEducation] = useState(false);
    const handleCloseEducation = () => setShowEducation(false);
    const handleShowEducation = () => setShowEducation(true);
    

    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const customStyles = {   
        zIndex: '99999'
      };
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="form-group align-items-center">
                    <Col md="12">
                        <div className="profile-img-edit">
                            <img className="profile-pic" src={img1} alt="profile-pic" />
                            <div className="p-image">
                                <i className="ri-pencil-line upload-button text-white"></i>
                                <input className="file-upload" type="file" accept="image/*" />
                            </div>
                        </div>
                    </Col>
                </Form.Group>
                <Row className="align-items-center">
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="fname" className="form-label">First Name:</Form.Label>
                        <Form.Control type="text" className="form-control" id="fname" placeholder="Bni" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="lname" className="form-label">Last Name:</Form.Label>
                        <Form.Control type="text" className="form-control" id="lname" placeholder="Jhon" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="uname" className="form-label">User Name:</Form.Label>
                        <Form.Control type="text" className="form-control" id="uname" placeholder="Bni@01" />
                    </Form.Group>

                    <Form.Group className="form-group col-sm-6">
                        <Form.Label className="form-label">Country:</Form.Label>
                        {/* <Select options={options} value={value} onChange={changeHandler} /> */}
                        <CountryDropdown className="form-select" value={country} onChange={(val) => setCountry(val)} />{" "}
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label className="form-label">State:</Form.Label>
                        {/* <Form.Select defaultValue="Georgia" className="form-select" aria-label="Default select example 4">
                <option>California</option>
                <option>Florida</option>
                <option>Georgia</option>
                <option>Connecticut</option>
                <option>Louisiana</option>
            </Form.Select> */}
                        <RegionDropdown className="form-select" country={country} value={region} onChange={(val) => setRegion(val)} />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="cname" className="form-label">City:</Form.Label>
                        <Form.Control type="text" className="form-control" id="cname" placeholder="Atlanta" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label className="form-label d-block">Gender:</Form.Label>
                        <Form.Check className="form-check form-check-inline">
                            <Form.Check.Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio10" defaultValue="option1" />
                            <Form.Check.Label className="form-check-label" htmlFor="inlineRadio10"> Male</Form.Check.Label>
                        </Form.Check>
                        <Form.Check className="form-check form-check-inline">
                            <Form.Check.Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio11" defaultValue="option1" />
                            <Form.Check.Label className="form-check-label" htmlFor="inlineRadio11">Female</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="dob" className="form-label">Date Of Birth:</Form.Label><br />
                        {/* <Form.Control className="form-control" id="dob" placeholder="1984-01-24"/> */}
                        <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                        {/* <Datepickers names="dob" className="form-control" placeholder="Date picker" /> */}
                    </Form.Group>
                    {/* <Form.Group className="form-group col-sm-6">
      <Form.Label className="form-label">Marital Status:</Form.Label>
      <Form.Select defaultValue="Single" className="form-select" aria-label="Default select example">
          <option>Single</option>
          <option>Married</option>
          <option>Widowed</option>
          <option>Divorced</option>
          <option>Separated </option>
      </Form.Select>
  </Form.Group>
  <Form.Group className="form-group col-sm-6">
      <Form.Label className="form-label">Age:</Form.Label>
      <Form.Select className="form-select" aria-label="Default select example 2">
      <option>46-62</option>
      <option>63 </option>
      </Form.Select>
  </Form.Group> */}
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="fathername" className="form-label">Father Name:</Form.Label>
                        <Form.Control type="text" className="form-control" id="fathername" placeholder="Bni" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="mothername" className="form-label">Mother Name:</Form.Label>
                        <Form.Control type="text" className="form-control" id="mothername" placeholder="Jhon" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-12">
                        <Form.Label className="form-label">Education:</Form.Label>
                        <Form.Select defaultValue="Single" className="form-select" aria-label="Default select example">
                            <option>Andhra University</option>
                            <option>Osmania University</option>
                            <option>SV University</option>
                            <option>JNTU, Kakinada</option>
                            <option>JNTU, Hyderabad </option>
                        </Form.Select>
                    </Form.Group>
                    <div className='col-sm-3'>
                        <Button variant="outline-primary" className=" d-inline-flex mb-1" onClick={handleShowEducation}><i className="material-symbols-outlined me-1">Add</i>Education</Button>{' '}
                        <Modal show={showEducation} onHide={handleCloseEducation} style={customStyles} >
                            <Modal.Header closeButton>
                                <Modal.Title>Education</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <div className='col-sm-12'>
                                        <Row className="align-items-center">
                                        <Form.Group className="form-group col-sm-6">
                                                <Form.Label htmlFor="fstudy" className="form-label">School:</Form.Label>
                                                <Form.Control type="text" className="form-control" id="fstudy" placeholder="Ex. Vikas Concept School" />
                                            </Form.Group>
                                            {/* <Form.Group className="form-group col-sm-6">
                                                <Form.Label htmlFor="fstudy" className="form-label">Degree:</Form.Label>
                                                <Form.Control type="text" className="form-control" id="fstudy" placeholder="Ex. Bachelor's" />
                                            </Form.Group> */}
                                            <Form.Group className="form-group col-sm-6">
                                                <Form.Label htmlFor="fstudy" className="form-label">Field of Study:</Form.Label>
                                                <Form.Control type="text" className="form-control" id="fstudy" placeholder="Ex. Science" />
                                            </Form.Group>
                                            <Form.Group className="form-group col-sm-6">
                                                <Form.Label htmlFor="dob" className="form-label">Start Date:</Form.Label><br />                                                
                                                <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />                                               
                                            </Form.Group>
                                            <Form.Group className="form-group col-sm-6">
                                                <Form.Label htmlFor="dob" className="form-label">End Date:</Form.Label><br />                                                
                                                <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />                                                
                                            </Form.Group>
                                            <Form.Group className="form-group col-sm-6">
                                                <Form.Label htmlFor="fstudy" className="form-label">Grade:</Form.Label>
                                                <Form.Control type="text" className="form-control" id="fstudy" placeholder="Ex. A+" />
                                            </Form.Group> 
                                            <Form.Group className="form-group col-sm-12">
                                                <Form.Label htmlFor="fstudy" className="form-label">Description:</Form.Label>
                                                <Form.Control as="textarea" className="form-control" rows={3} style={{ lineHeight: "22px" }} placeholder=" ">
                                                </Form.Control>
                                            </Form.Group>  
                                        </Row>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseEducation}>
                                    Save
                                </Button>

                            </Modal.Footer>
                        </Modal> 

                    </div>


                    {/* <button onClick={toggleCollapse} className="btn btn-primary me-2">
                        {isCollapsed ? 'Education Details' : 'Education Details'}
                    </button>
                    {!isCollapsed && ( */}
                        <div className='col-sm-12'>
                            <Row className="align-items-center">
                                <Form.Group className="form-group col-sm-6">
                                    <Form.Label className="form-label">Languages:</Form.Label>
                                    <Form.Select defaultValue="USA" className="form-select" aria-label="Default select example 3">
                                        <option>English</option>
                                        <option>Hindi</option>
                                        <option>French</option>
                                    </Form.Select>
                                    {/* <ReactLanguageSelect  className="form-select" names={"international"} onSelect={(languageCode)=>setSelectedLanguage(languageCode)} /> */}
                                </Form.Group>
                                <Form.Group className="form-group col-sm-6">
                                    <Form.Label htmlFor="school" className="form-label">School:</Form.Label>
                                    <Form.Control type="text" className="form-control" id="school" placeholder="Hacienda Elementary School" />
                                </Form.Group>
                                <Form.Group className="form-group col-sm-6">
                                    <Form.Label htmlFor="degree" className="form-label">Degree:</Form.Label>
                                    <Form.Control type="text" className="form-control" id="degree" placeholder="California City College" />
                                </Form.Group>
                                <Form.Group className="form-group col-sm-6">
                                    <Form.Label htmlFor="fstudy" className="form-label">Field of Study:</Form.Label>
                                    <Form.Control type="text" className="form-control" id="fstudy" placeholder="Music and dance" />
                                </Form.Group>
                            </Row>
                        </div>
                    {/* )} */}
                    {/* <Form.Group className="form-group col-sm-12">
      <Form.Label className="form-label">Address:</Form.Label>
      <Form.Control as="textarea" className="form-control" rows={5} style={{lineHeight: "22px"}} placeholder=" 37 Cardinal Lane
      Petersburg, VA 23803
      United States of America
      Zip Code: 85001">
      </Form.Control>
  </Form.Group> */}
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="activities" className="form-label">Activities:</Form.Label>
                        <Form.Control type="text" className="form-control" id="activities" placeholder="Dancing, Painting" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="achievements" className="form-label">Achievements:</Form.Label>
                        <Form.Control type="text" className="form-control" id="achievements" placeholder="Capezio Dance Award" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="goals" className="form-label">Goals:</Form.Label>
                        <Form.Control type="text" className="form-control" id="goals" placeholder="Make a certain dance team, cheerleading team" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="hobbies" className="form-label">Hobbies:</Form.Label>
                        <Form.Control type="text" className="form-control" id="hobbies" placeholder="My hobby is dancing, as it gives me joy and happiness." />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="certifications" className="form-label">Certifications:</Form.Label>
                        <Form.Control type="text" className="form-control" id="certifications" placeholder="National Dance Council of America (NDCA)" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="awards" className="form-label">Awards:</Form.Label>
                        <Form.Control type="text" className="form-control" id="awards" placeholder="Capezio Dance Award" />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="aoi" className="form-label">Area of Interest:</Form.Label>
                        <Form.Control type="text" className="form-control" id="aoi" placeholder="Dance" />
                    </Form.Group>
                </Row>
                <Button type="submit" className="btn btn-primary me-2">Submit</Button>
                <Button type="reset" className="btn bg-soft-danger">Cancel</Button>
            </Form>
        </>
    )
}

export default UserPersonalProfile;