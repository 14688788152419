import React from 'react'
import { Row, Card, Container, Col, ListGroup, ListGroupItem, Button, CardGroup } from 'react-bootstrap'
//import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'

// images
import gi1 from '../../../assets/images/page-img/gi-1.jpg'
import gi2 from '../../../assets/images/page-img/gi-2.jpg'
import gi3 from '../../../assets/images/page-img/gi-3.jpg'
import gi4 from '../../../assets/images/page-img/gi-4.jpg'
import gi5 from '../../../assets/images/page-img/gi-5.jpg'
import gi6 from '../../../assets/images/page-img/gi-6.jpg'
import gi7 from '../../../assets/images/page-img/gi-7.jpg'
import gi8 from '../../../assets/images/page-img/gi-8.jpg'
import gi9 from '../../../assets/images/page-img/gi-9.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user07 from '../../../assets/images/user/07.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user09 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import img1 from '../../../assets/images/page-img/profile-bg1.jpg'
import img2 from '../../../assets/images/page-img/profile-bg2.jpg'
import img3 from '../../../assets/images/page-img/profile-bg3.jpg'
import img4 from '../../../assets/images/page-img/profile-bg4.jpg'
import img5 from '../../../assets/images/page-img/profile-bg5.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import img7 from '../../../assets/images/page-img/profile-bg7.jpg'
import img9 from '../../../assets/images/page-img/profile-bg9.jpg'

const ReqGroups = () => {
    const items = [
        { id: 1, imageSrc: user05, text: 'Carnatic Song', members:'120' },
        { id: 2, imageSrc: user06, text: 'TeamWork', members:'180'  },
        { id: 3, imageSrc: user07, text: 'Photography', members:'325'  },
        { id: 4, imageSrc: user08, text: 'Paining', members:'150'  },
        { id: 5, imageSrc: user10, text: 'Daily Quiz', members:'210'  },
    ];
    return (
        <>
            {/* <ProfileHeader  img={img7} title="Groups" /> */}
            <div id="content-page" className="content-page">
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card className="position-relative inner-page-bg bg-primary" style={{ height: "150px" }}>
                                <div className="inner-page-title">
                                    <h3 className="text-white">Requested Groups</h3>
                                    {/* <p className="text-white">lorem ipsum</p> */}
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <div className="d-grid gap-1 d-grid-template-1fr-19">
                        <ul className="list-group">
                            {items.map((item) => (
                                <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-left">
                                        <img src={item.imageSrc} alt="Item" width="50" height="50" className="mr-3" />
                                       
                                        <div className="ms-3">
                                            <p className=" h4 ">{item.text}</p>
                                            <small>{item.members} members</small>
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary btn-sm dropdown-toggle"
                                            type="button"
                                            id={`dropdownMenuButton-${item.id}`}
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="fas fa-ellipsis-h"></i>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${item.id}`}>
                                            <a className="dropdown-item" href="#">Action 1</a>
                                            <a className="dropdown-item" href="#">Action 2</a>
                                            <a className="dropdown-item" href="#">Action 3</a>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Container>
            </div>
        </>
    )

}

export default ReqGroups;