import React from 'react';
import { useState, useMemo } from 'react';
import { Container, Row, Col, Card, Tab, Form, Button, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Select from 'react-select'
import countryList from 'react-select-country-list'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const UserHobbiesProfile = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    return (<>
        <Form onSubmit={handleSubmit}>
            <Row className="align-items-center">
                <Form.Group className="form-group">
                    <Form.Label>Hobbies</Form.Label>
                    <Form.Control as="textarea" rows="3"></Form.Control>
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Favourite TV Shows</Form.Label>
                    <Form.Control type="text" className="form-control" id="fname" placeholder="" />
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Favourite Movies</Form.Label>
                    <Form.Control type="text" className="form-control" id="fname" placeholder="" />
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Favourite Games</Form.Label>
                    <Form.Control type="text" className="form-control" id="fname" placeholder="" />
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Favourite Music Bands / Artists:</Form.Label>
                    <Form.Control type="text" className="form-control" id="fname" placeholder="" />
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Favourite Books</Form.Label>
                    <Form.Control type="text" className="form-control" id="fname" placeholder="" />
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Favourite Writers</Form.Label>
                    <Form.Control type="text" className="form-control" id="fname" placeholder="" />
                </Form.Group>  
                <Button variant="primary">Submit</Button>{' '}              
            </Row>
        </Form>
    </>)
}

export default UserHobbiesProfile;